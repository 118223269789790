import { FC } from 'react';
import { LoginCredentials } from '@/features/auth/types/Auth.types';
import { useRequestUserLoginMutation } from '@/features/auth/services/Auth.service';
import { Alert, Button, Column, Password, Row, Svg, Text } from '@gourban/ui-components';
import styles from '@/features/auth/assets/scss/LoginForm.module.scss';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { loginSchema } from '@/features/auth/utils/validations/login.schema';
import { ApiError } from '@/core/types';
import { t, Trans } from '@lingui/macro';
import useAfterAuthPathResolver from '@/features/auth/hooks/useAfterAuthPathResolver';

export interface LoginFormT {
  onUnverifiedError: (email: string) => void;
}

const EmailLoginForm: FC<LoginFormT> = ({ onUnverifiedError }) => {
  const resolvePath = useAfterAuthPathResolver();
  const [requestUserLogin, { error: loginError }] = useRequestUserLoginMutation();
  const error = loginError as ApiError;

  const onSubmitForm = (credentials: LoginCredentials) =>
    requestUserLogin(credentials)
      .unwrap()
      .then(resolvePath)
      .catch((err: ApiError) => {
        // code for "Email isn't verified" error
        if (err.errorCode === 'AUTH108') {
          onUnverifiedError(credentials.email);
        }
      });

  const translations = {
    email: t({
      id: 'auth.forms.email',
      message: 'E-mail',
    }),
    password: t({
      id: 'auth.forms.password',
      message: 'Password',
    }),
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={loginSchema}
      onSubmit={onSubmitForm}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            {!!error && (
              <Alert
                className={styles['login-form__alert']}
                type="error"
                title={t({
                  id: 'error',
                  message: 'Error',
                })}
                description={error.userMessage}
              />
            )}

            <Row marginBottom={8} gapSm="sm" column>
              <Column>
                <Text
                  name="email"
                  fieldAttr={{
                    id: 'email',
                    placeholder: translations.email,
                    autoComplete: 'email',
                    type: 'email',
                    required: true,
                  }}
                  fieldProps={{
                    size: 'large',
                    label: translations.email,
                  }}
                />
              </Column>

              <Column>
                <Password
                  name="password"
                  fieldAttr={{
                    id: 'password',
                    placeholder: translations.password,
                    autoComplete: 'password',
                    required: true,
                  }}
                  fieldProps={{
                    size: 'large',
                    label: translations.password,
                  }}
                />
              </Column>
            </Row>

            <Row marginBottom={16} justify="space-between">
              <Column />
              <Column className={styles['login-form__forgot-password']}>
                <Link to="/auth/forgot-password">
                  <Svg icon="lock" width={16} height={16} />
                  <Trans id="auth.forgotPasswordQuestion">Forgot password?</Trans>
                </Link>
              </Column>
            </Row>

            <Row>
              <Button
                loading={isSubmitting}
                width="full"
                size="large"
                attributes={{ type: 'submit' }}
              >
                <Trans id="auth.loginForm.submit">Sign in</Trans>
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailLoginForm;
