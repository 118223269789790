import React from 'react';
import { Trans } from '@lingui/macro';
import { Button, Paragraph, Row } from '@gourban/ui-components';
import styles from '@/features/businessAccounts/assets/scss/Restrictions/Approval.module.scss';
import { useNavigate } from 'react-router-dom';
import Banner from '@/core/components/Banner';
import useMailtoLink from '@/core/hooks/useMailtoLink';
import PageContent from '@/core/components/PageContent';

const ApprovalRejected = () => {
  const navigate = useNavigate();
  const { mailto, email } = useMailtoLink();
  return (
    <PageContent>
      <Banner
        className={styles.approval}
        header={<Trans id="businessAccounts.approvalRejected.title">Account blocked</Trans>}
        message={
          <Row justify="center">
            <Paragraph marginBottom={16} align="center">
              <Trans id="businessAccounts.approvalRejected.description">
                You account has been blocked.
              </Trans>
            </Paragraph>
            <Paragraph marginBottom={32} align="center">
              <Trans id="businessAccounts.approvalRejected.contactSupport">
                Please contact our support team at <a href={mailto}>{email}</a> for further
                inquiries.
              </Trans>
            </Paragraph>

            <Button onClick={() => navigate('/')}>
              <Trans id="businessAccount.approvalRejected.booking">Booking</Trans>
            </Button>
          </Row>
        }
        icon="profile"
        iconColor="icon-950"
      />
    </PageContent>
  );
};

export default ApprovalRejected;
