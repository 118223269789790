import { FC } from 'react';
import cssModules from '@/core/assets/scss/pages/Page404.module.scss';
import { Svg, Button, Heading } from '@gourban/ui-components';
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';

interface Page404T {
  goBackTo?: string;
}

const Page404: FC<Page404T> = ({ goBackTo = '/' }) => {
  const navigate = useNavigate();

  const redirectUser = () => {
    navigate(goBackTo);
  };

  return (
    <div className={cssModules.Page404}>
      <Svg width={40} icon="triangleWarning" />
      <Heading size={3}>404</Heading>
      <Heading marginBottom={20} size={5}>
        <Trans id="pages.notFound.title">Oops, we can't find the page you're looking for</Trans>
      </Heading>
      <Button onClick={redirectUser} size="medium">
        <Trans id="general.goBack">Go back</Trans>
      </Button>
    </div>
  );
};

export default Page404;
