import React, { FC } from 'react';
import { PaymentSource as Source } from '@/features/payment/types/Payment.types';
import styles from '@/features/payment/assets/PaymentSource.module.scss';
import { Column, Row, Paragraph, Caps, Button } from '@gourban/ui-components';
import CreditCardLogo from '@/features/payment/components/CreditCardLogo';
import { format } from 'date-fns';
import { useTypedDispatch } from '@/core/redux/hooks';
import { setSelectedCardId } from '@/features/account/redux/account.reducer';
import {
  useLazyRequestPaymentSourcesQuery,
  useRequestSetDefaultPaymentSourceMutation,
} from '@/features/payment/services/Payment.service';
import { BusinessAccount } from '@/features/businessAccounts/types';
import { Trans } from '@lingui/macro';

interface PaymentSourceT {
  paymentSource: Source;
  selected: boolean;
  businessAccount?: BusinessAccount;
  onRequestDelete: (id: Source) => void;
  branchId: number;
}

const PaymentSource: FC<PaymentSourceT> = ({
  paymentSource,
  selected,
  businessAccount,
  onRequestDelete,
  branchId,
}) => {
  const dispatch = useTypedDispatch();
  const [setDefaultPaymentMethod] = useRequestSetDefaultPaymentSourceMutation();
  const [requestPaymentSources] = useLazyRequestPaymentSourcesQuery();

  const setSelected = () => {
    if (!businessAccount) {
      // Only updated user selectedCardID if its not business-account related
      dispatch(setSelectedCardId(paymentSource.id));
    }
    setDefaultPaymentMethod({
      sourceId: paymentSource.id,
      userGroupId: businessAccount?.id,
      branchId,
    });
  };

  if (paymentSource.state === 'PENDING') {
    return (
      <Row marginBottom={32} className={styles['payment-source']}>
        <Column className={styles['payment-source__content']}>
          <Row gapSm="sm">
            <Column>
              <Paragraph marginBottom={0}>
                <Trans id="account.paymentSource.cardPendingState">
                  Payment source in <b>Pending</b> state
                </Trans>
              </Paragraph>
            </Column>
            <Column>
              <Paragraph textColor="text-400" size={4} marginBottom={0}>
                <Trans id="account.paymentSource.cardBeingVerified">
                  Payment source added but it is currently being verified. This shouldn't take
                  longer than a few minutes.
                </Trans>
              </Paragraph>
            </Column>
            <Column>
              <Button
                onClick={() =>
                  requestPaymentSources({ userGroupId: businessAccount?.id, branchId })
                }
                size="small"
                variation="secondary"
              >
                <Trans id="general.refresh">Refresh</Trans>
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }

  return (
    <Row marginBottom={32} className={styles['payment-source']}>
      <Column sm={12} className={styles['payment-source__content']}>
        <Row>
          <Column sm={12}>
            {paymentSource.type === 'CC' && (
              <Paragraph marginBottom={0} size={2}>
                **** **** **** {paymentSource.last4}
              </Paragraph>
            )}
          </Column>
          <Column sm={12}>
            <Paragraph textColor="text-300" size={4}>
              {paymentSource.type === 'CC' &&
                format(new Date(paymentSource.expYear!, paymentSource.expMonth! - 1), 'MM / yy')}
            </Paragraph>
          </Column>

          <Column marginBottom={16} sm={12}>
            <Row justify="flex-end">
              <CreditCardLogo type={paymentSource.type} brand={paymentSource.brandNormalized} />
            </Row>
          </Column>
        </Row>
      </Column>

      <Column sm={12}>
        <Row
          alignItems="center"
          justify="space-between"
          className={styles['payment-source__footer']}
        >
          <Column>
            {selected ? (
              <Caps size={3} textColor="text-600">
                <Trans id="account.paymentSource.preferredCard">Preferred</Trans>
              </Caps>
            ) : (
              <Button size="small" noPadding onClick={setSelected} variation="link">
                <Trans id="account.paymentSource.setPreferred">Set as preferred</Trans>
              </Button>
            )}
          </Column>
          {!selected && (
            <Column>
              <Button
                onClick={() => onRequestDelete(paymentSource)}
                size="small"
                noPadding
                variation="link"
                iconName="trashFilled"
                attributes={{
                  'aria-label': 'Delete Payment Source',
                }}
              />
            </Column>
          )}
        </Row>
      </Column>
    </Row>
  );
};

export default PaymentSource;
