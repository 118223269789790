import { FC, ReactNode } from 'react';
import { Heading, Paragraph, Spacer, Row } from '@gourban/ui-components';

interface AuthenticationLayoutT {
  title: ReactNode;
  form: ReactNode;
  footer: ReactNode;
  alignCenter?: boolean;
}

const AuthenticationLayout: FC<AuthenticationLayoutT> = ({ title, form, footer, alignCenter }) => {
  return (
    <>
      <Heading marginBottom={4} weight="regular" size={3} align={alignCenter ? 'center' : 'left'}>
        {title}
      </Heading>
      {form}
      <Spacer />
      <Row justify={alignCenter ? 'center' : 'flex-start'}>
        <Paragraph size={4} weight="medium" align={alignCenter ? 'center' : 'left'}>
          {footer}
        </Paragraph>
      </Row>
    </>
  );
};

export default AuthenticationLayout;
