import React from 'react';
import { Avatar, Loader } from '@gourban/ui-components';
import { getInitials } from '../utils/getInitials';
import { useRequestMeDataQuery } from '@/features/account/services/Account.service';

const UserAvatar: React.FC = () => {
  const { data: userData, isFetching } = useRequestMeDataQuery();

  return (
    <Avatar
      fontSize={2}
      image={userData?.customProperties?.avatar}
      fallback={
        isFetching ? (
          <Loader width={23} />
        ) : (
          getInitials(`${userData?.firstName} ${userData?.lastName}`)
        )
      }
      textColor="text-0"
    />
  );
};

export default UserAvatar;
