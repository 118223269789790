import React, { FC, Fragment } from 'react';
import { Row, Column, Tags, Tooltip, Paragraph } from '@gourban/ui-components';
import { VehicleCategoryFeatures } from '@/features/vehicles/types';

interface BookingVehicleCategoryTagsT {
  features: VehicleCategoryFeatures[];
}

const VehicleCategoryTags: FC<BookingVehicleCategoryTagsT> = ({ features }) => {
  return (
    <Row gapSm="xs">
      {features.slice(0, 2).map((feature) => (
        <Column key={feature.title}>
          <Tags size="tiny" content={feature.title} />
        </Column>
      ))}
      {features.length > 2 && (
        <Column>
          <Tooltip
            placement="bottom"
            content={
              <Paragraph marginBottom={0} textColor="text-0" size={4}>
                {features.slice(2, features.length).map((feature) => (
                  <Fragment key={feature.title}>
                    {feature.title} <br />
                  </Fragment>
                ))}
              </Paragraph>
            }
          >
            <Tags size="tiny" content="..." weight="bold" />
          </Tooltip>
        </Column>
      )}
    </Row>
  );
};

export default VehicleCategoryTags;
