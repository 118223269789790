import React, { useEffect, useMemo, useState, useCallback } from 'react';
import defaultPin from '../assets/images/defaultPin.png';
import activeDefaultPin from '../assets/images/activeDefaultPin.png';
import { useRequestAllBranchesQuery } from '@/features/branches/services/Branches.service';
import {
  Loader,
  Paragraph,
  MapboxEvent,
  MapMouseEvent,
  Layer,
  MapRef,
  Source,
  useMap,
} from '@gourban/ui-components';
import { FeatureCollection, Point, Feature, Polygon } from 'geojson';
import { useNavigate, useParams } from 'react-router-dom';
import { Branch } from '../../branches/types';
import DiscardChangesModal from '@/core/components/DiscardChangesModal';
import polygonContains from '@turf/boolean-contains';
import transform from '@turf/transform-scale';
import Filters from '@/features/booking/components/Filters/Filters';
import { useLazyRequestAvailableStationsQuery } from '@/features/booking/services/Booking.service';
import {
  AvailableBookingStationArgs,
  AvailableBookingStationFilters,
} from '@/features/booking/types';
import {
  getActiveFilterValues,
  getBookingCreationProcessStatus,
} from '@/features/account/redux/account.selectors';
import { useTypedDispatch, useTypedSelector } from '@/core/redux/hooks';
import { Trans, t } from '@lingui/macro';
import { isEmpty, pick, values as objValues } from 'lodash';
import { createPolygonFromPoints } from '@/features/booking/utils/createPolygonFromPoints';
import { setFilteredStationsData } from '@/features/account/redux/account.reducer';
import styles from '@/features/geomap/assets/scss/Stations.module.scss';

let IDRef: string | undefined;
let activeFiltersRef: Partial<AvailableBookingStationFilters> | null = null;
let bookingCreationProcessStarted: boolean | null;
let currentBoundingBoxCoordinates: number[] | null = null;
let abortReq: AbortController['abort'] | null = null;

const Stations = () => {
  const { data: branchesData, isFetching } = useRequestAllBranchesQuery();
  const [
    requestFilteredStations,
    { data: filteredStations, isFetching: isFetchingStations, isLoading: isLoadingStations },
  ] = useLazyRequestAvailableStationsQuery();
  const { current: mapInstance } = useMap();
  const navigate = useNavigate();
  const [requestedStationId, setRequestedStationId] = useState<number | null>(null);
  const { branchId } = useParams();
  const activeFilters = useTypedSelector(getActiveFilterValues);
  const bookingCreationProcessStatus = useTypedSelector(getBookingCreationProcessStatus);
  const dispatch = useTypedDispatch();

  IDRef = branchId;
  bookingCreationProcessStarted = bookingCreationProcessStatus;
  activeFiltersRef = activeFilters;

  const resolveStationImages = useCallback(
    (branch: Branch) => {
      if (branchId && branch.id === +branchId) {
        if (branch?.appProperties?.['stationImage.selected']) {
          return `${branch.name}.selected`;
        }

        return 'activeDefaultPin';
      }

      const areVehicleAttributesEmpty = objValues(activeFilters?.vehicleAttributes).every((attr) =>
        isEmpty(attr),
      );

      if (!areVehicleAttributesEmpty || !!activeFilters?.startTime) {
        const station = filteredStations?.find(
          ({ branch: filteredBranch }) => branch.id === filteredBranch.id,
        );

        if (!station) {
          return '';
        }

        if (!station?.availableCategories?.length) {
          if (branch?.appProperties?.['stationImage.noFilterMatch']) {
            return `${branch.name}.noFilterMatch`;
          }

          return '';
        }
      }

      if (branch?.appProperties?.stationImage) {
        return `${branch.name}.default`;
      }

      return 'defaultPin';
    },
    [activeFilters, filteredStations, branchId],
  );

  const getFormattedStations = useCallback(
    (stations: Branch[]) => {
      return stations.reduce<FeatureCollection<Point>>(
        (featureCollection, branch) => {
          /**
           * Note: when filtered, backend always returns STATION_BASED, and they dont return types,
           * so when they are not present it means they are stations
           */
          if (branch.types?.includes('STATION_BASED') || !branch.types) {
            featureCollection.features.push({
              type: 'Feature',
              properties: {
                ...branch,
                stationImage: resolveStationImages(branch),
              },
              id: branch.id,
              geometry: branch.position,
            });
          }

          return featureCollection;
        },
        { type: 'FeatureCollection', features: [] },
      );
    },
    [resolveStationImages],
  );

  const geoJSONCollection = useMemo(() => {
    if (!branchesData) return undefined;

    return getFormattedStations(branchesData);
  }, [getFormattedStations, branchesData]);

  const animateMapTo = ({ lat, lng }: { lat: number; lng: number }) => {
    mapInstance!.flyTo({
      center: {
        lat,
        lng,
      },
      duration: 1500,
      zoom: 18,
      offset: [-120, 0],
    });
  };

  const addImageInstance = (src: string, name: string) => {
    if (!mapInstance!.hasImage(name)) {
      mapInstance!.loadImage(src, (error, image) => {
        if (error || !image) return;
        const targetWidth = 150;
        const { width, height } = image;
        const aspectRatio = width / height;

        const img = new Image(targetWidth, targetWidth / aspectRatio);
        img.crossOrigin = 'Anonymous';

        img.onload = () => {
          mapInstance!.addImage(name, img);
        };
        img.src = src;
      });
    }
  };

  const addMapImageReferences = () => {
    // We need to add default images to mapInstance so layers can access it
    addImageInstance(defaultPin, 'defaultPin');
    addImageInstance(activeDefaultPin, 'activeDefaultPin');

    if (!branchesData?.length) {
      return;
    }

    // We need to add each specific image from each branch if provided as well
    branchesData.forEach((branch) => {
      if (branch.appProperties?.stationImage) {
        addImageInstance(branch.appProperties.stationImage, `${branch.name}.default`);
      }

      if (branch.appProperties?.['stationImage.noFilterMatch']) {
        addImageInstance(
          branch.appProperties['stationImage.noFilterMatch'],
          `${branch.name}.noFilterMatch`,
        );
      }

      if (branch.appProperties?.['stationImage.selected']) {
        addImageInstance(branch.appProperties['stationImage.selected'], `${branch.name}.selected`);
      }
    });
  };

  const scalePolygon = (polygon: Polygon) => {
    const scaledPolygon = transform(polygon, 1.7);

    return {
      scaledPolygon,
      latMax: scaledPolygon.coordinates[0][1][0],
      latMin: scaledPolygon.coordinates[0][0][0],
      lngMax: scaledPolygon.coordinates[0][0][1],
      lngMin: scaledPolygon.coordinates[0][2][1],
    };
  };

  const onFilterSubmit = (values: Partial<AvailableBookingStationArgs>) => {
    // If location is different we dont want to submit the filters, because this will be handled on map move end
    // Reason is because we dont have the coordinates of bounding box of new location, so we need to wait for it first before we can submit
    if (
      activeFilters?.location &&
      values?.location &&
      activeFilters?.location !== values?.location
    ) {
      return null;
    }

    if (abortReq) {
      abortReq();
      abortReq = null;
    }

    const { _ne: northEastPoint, _sw: southWestPoint } = mapInstance?.getBounds() ?? {};

    // we want to scale the polygon so we can get more stations in the view so not every map change will trigger it
    const { latMax, latMin, lngMax, lngMin } = scalePolygon(
      createPolygonFromPoints(
        { lat: northEastPoint?.lat!, lng: northEastPoint?.lng! },
        { lat: southWestPoint?.lat!, lng: southWestPoint?.lng! },
      ),
    );

    const boundBoxArgs = {
      latMax,
      latMin,
      lngMax,
      lngMin,
    };

    currentBoundingBoxCoordinates = mapInstance!.getBounds()!.toArray().flat();

    const req = requestFilteredStations({ ...values, ...boundBoxArgs });
    abortReq = req.abort;

    return req.unwrap().then((response) => {
      dispatch(setFilteredStationsData(response));
      abortReq = null;
    });
  };

  useEffect(() => {
    // If ID exists we need to initial set view state to that station
    if (branchId) {
      const branchData = branchesData?.find((branch) => branch.id === Number(branchId));

      if (branchData) {
        animateMapTo({
          lat: branchData.position.coordinates[1],
          lng: branchData.position.coordinates[0],
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mapInstance) return undefined;

    currentBoundingBoxCoordinates = mapInstance.getBounds()!.toArray().flat();

    addMapImageReferences();

    const clickHandler = (
      event: MapMouseEvent & {
        features?: Feature[];
      },
    ) => {
      const feature = event.features?.[0];

      if (feature) {
        const properties = feature.properties as Branch;

        if (IDRef) {
          if (Number(IDRef) !== properties.id && bookingCreationProcessStarted) {
            setRequestedStationId(properties.id);
            return;
          }
        }

        const branchData = branchesData?.find((branch) => branch.id === Number(properties.id));

        animateMapTo({
          lat: branchData!.position.coordinates[1],
          lng: branchData!.position.coordinates[0],
        });

        navigate({ pathname: `/booking/${properties.id}`, search: window.location.search });
      }
    };

    const mouseEnterHandler = () => {
      mapInstance.getCanvas().style.cursor = 'pointer';
    };

    const mouseLeaveHandler = () => {
      mapInstance.getCanvas().style.cursor = '';
    };

    const handleMoveEnd = (event: MapboxEvent & { target: MapRef }) => {
      const hasActiveFilters =
        activeFiltersRef?.startTime || !isEmpty(activeFiltersRef?.vehicleAttributes);

      // We only want to trigger following request when filters are active
      if (hasActiveFilters && !IDRef) {
        const nextActiveView = (event.target as MapRef).getBounds()!.toArray().flat();

        const { scaledPolygon } = scalePolygon(
          createPolygonFromPoints(
            { lat: currentBoundingBoxCoordinates![0], lng: currentBoundingBoxCoordinates![1] },
            { lat: currentBoundingBoxCoordinates![2], lng: currentBoundingBoxCoordinates![3] },
          ),
        );

        // if polygon is contained in the previous bounding box we dont want to submit the filters since we have the data already
        if (
          polygonContains(
            scaledPolygon,
            createPolygonFromPoints(
              { lat: nextActiveView[0], lng: nextActiveView[1] },
              { lat: nextActiveView[2], lng: nextActiveView[3] },
            ),
          )
        ) {
          return;
        }

        const { _ne: northEastPoint, _sw: southWestPoint } =
          (event.target as MapRef).getBounds() ?? {};

        // bounding box of scaled polygon that is sent to backend filters
        const { latMax, latMin, lngMax, lngMin } = scalePolygon(
          createPolygonFromPoints(
            { lat: northEastPoint?.lat!, lng: northEastPoint?.lng! },
            { lat: southWestPoint?.lat!, lng: southWestPoint?.lng! },
          ),
        );

        const boundBoxArgs = {
          latMax,
          latMin,
          lngMax,
          lngMin,
        };

        currentBoundingBoxCoordinates = nextActiveView;

        onFilterSubmit({
          ...pick(activeFiltersRef, ['startTime', 'endTime', 'vehicleAttributes']),
          ...boundBoxArgs,
        });
      }
    };

    // On pin click handler
    mapInstance.on('click', 'pin', clickHandler);
    mapInstance.on('mousemove', 'pin', mouseEnterHandler);
    mapInstance.on('mouseleave', 'pin', mouseLeaveHandler);
    // @ts-ignore
    mapInstance.on('moveend', handleMoveEnd);

    return () => {
      mapInstance.off('click', 'pin', clickHandler);
      mapInstance.off('mousemove', 'pin', mouseEnterHandler);
      mapInstance.off('mouseleave', 'pin', mouseLeaveHandler);
      // @ts-ignore
      mapInstance.off('moveend', handleMoveEnd);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) return <Loader cover />;

  return (
    <>
      <DiscardChangesModal
        opened={!!requestedStationId}
        onConfirm={() => {
          const branchData = branchesData!.find(
            (branch) => branch.id === Number(requestedStationId),
          );

          navigate({ pathname: `/booking/${requestedStationId}`, search: window.location.search });

          animateMapTo({
            lat: branchData!.position.coordinates[1],
            lng: branchData!.position.coordinates[0],
          });
          setRequestedStationId(null);
        }}
        onCancel={() => setRequestedStationId(null)}
        heading={<Trans id="geomap.stationsSwitch.title">Change location</Trans>}
        description={
          <Trans id="geomap.stationsSwitch.description">
            Are you sure you want to change station? Your current booking will be lost?
          </Trans>
        }
        cancelButtonLabel={t({ id: 'general.cancel', message: 'Cancel' })}
        discardButtonLabel={t({ id: 'general.change', message: 'Change' })}
      />

      {isLoadingStations && (
        <Loader cover>
          <Paragraph size={4} marginBottom={0}>
            <Trans id="geomap.stations.fetchingStations">Loading stations...</Trans>
          </Paragraph>
        </Loader>
      )}

      {isFetchingStations && !isLoadingStations && (
        <div className={styles['stations--fetching']}>
          <Loader width={18} height={18} />{' '}
          <Paragraph size={4} marginBottom={0}>
            <Trans id="geomap.stations.loadingResults">Loading results...</Trans>
          </Paragraph>
        </div>
      )}

      <Filters onSubmit={onFilterSubmit} />

      <Source generateId id="stations" type="geojson" data={geoJSONCollection}>
        <Layer
          id="pin"
          type="symbol"
          source="stations"
          layout={{
            'icon-text-fit': 'both',
            'icon-image': ['get', 'stationImage'],
            'icon-allow-overlap': true,
            'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.2, 18, 0.7],
            'icon-anchor': 'bottom',
          }}
        />
      </Source>
    </>
  );
};

export default Stations;
