import React, { FC, useState } from 'react';
import {
  useRequestBusinessAccountMembershipQuery,
  useRequestBusinessAccountQuery,
  useRequestJoinBusinessAccountRequestsQuery,
  useRequestRetractJoinBusinessAccountMutation,
} from '@/features/businessAccounts/services/BusinessAccount.service';
import {
  Button,
  Caps,
  Column,
  Loader,
  Modal,
  Row,
  Separator,
  Tags,
  Text,
} from '@gourban/ui-components';
import { useNavigate } from 'react-router-dom';
import BusinessCard from '@/features/account/components/Edit/BusinessCard';
import { Trans, t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import JoinRequest from '@/features/businessAccounts/components/JoinRequests/JoinRequest';

const BusinessAccountJoinWidget: FC = () => {
  const { data: businessAccountOwner, isSuccess: fetchedBusinessAccountOwner } =
    useRequestBusinessAccountQuery();

  const {
    data: businessAccountMembership,
    isFetching: isFetchingBusinessAccountMembership,
    isSuccess: fetchedBusinessAccountMembership,
  } = useRequestBusinessAccountMembershipQuery(undefined, {
    skip: businessAccountOwner && fetchedBusinessAccountOwner,
  });

  const { data: businessAccountJoinRequested, isFetching: isFetchingJoinRequests } =
    useRequestJoinBusinessAccountRequestsQuery(undefined, {
      skip: !!businessAccountMembership?.length && fetchedBusinessAccountMembership,
    });

  const [retractRequest, { isLoading: isRetractingJoinRequest }] =
    useRequestRetractJoinBusinessAccountMutation();

  const navigate = useNavigate();
  const [joinApplicationCode, setJoinApplicationCode] = useState<string | null>(null);

  if (isFetchingJoinRequests || isFetchingBusinessAccountMembership) {
    return (
      <Row justify="center">
        <Loader />
      </Row>
    );
  }

  return (
    <>
      {businessAccountJoinRequested?.length ? (
        businessAccountJoinRequested.map((businessAccount) => (
          <BusinessCard
            key={businessAccount.id}
            name={businessAccount.group.name}
            action={
              <Row column>
                <Column marginBottom={4}>
                  <Tags
                    size="small"
                    content={<Trans id="accountEdit.business.pending">Pending</Trans>}
                  />
                </Column>
                <Column>
                  <Button
                    loading={isRetractingJoinRequest}
                    onClick={() => retractRequest(businessAccount.id)}
                    size="small"
                    variation="link"
                  >
                    <Trans id="general.cancel">Cancel</Trans>
                  </Button>
                </Column>
              </Row>
            }
          />
        ))
      ) : (
        <Row>
          <Column marginBottom={16} sm={12}>
            <Button
              onClick={() =>
                navigate('/business-account/create/company-info', {
                  state: { isFromStepSwitching: true },
                })
              }
              width="full"
            >
              <Trans id="accountEdit.business.createBusinessAccount">Create Business Account</Trans>
            </Button>
          </Column>
          <Column sm={12}>
            <Row gapSm="md" alignItems="center">
              <Column grow>
                <Separator />
              </Column>
              <Column>
                <Caps size={1} weight="regular">
                  <Trans id="accountEdit.business.or">OR</Trans>
                </Caps>
              </Column>
              <Column grow>
                <Separator />
              </Column>
            </Row>
          </Column>
          <Column sm={12}>
            <Formik
              initialValues={{ inviteCode: '' }}
              onSubmit={(values) => setJoinApplicationCode(values.inviteCode)}
            >
              {({ values }) => {
                return (
                  <Form>
                    <Row alignItems="flex-end" gapSm="sm">
                      <Column grow>
                        <Text
                          fieldAttr={{
                            id: 'inviteCode',
                            placeholder: t({
                              id: 'accountEdit.business.inviteCodePlaceholder',
                              message: 'Paste your invite code',
                            }),
                            required: true,
                          }}
                          name="inviteCode"
                          fieldProps={{
                            label: t({
                              id: 'accountEdit.business.inviteCode',
                              message: 'Invite code',
                            }),
                          }}
                        />
                      </Column>
                      <Column>
                        <Button attributes={{ type: 'submit' }} disabled={!values.inviteCode}>
                          <Trans id="general.submit">Submit</Trans>
                        </Button>
                      </Column>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Column>
        </Row>
      )}

      <Modal
        modalHeader="Join request"
        onClose={() => setJoinApplicationCode(null)}
        opened={!!joinApplicationCode}
      >
        {!!joinApplicationCode && <JoinRequest code={joinApplicationCode} />}
      </Modal>
    </>
  );
};

export default BusinessAccountJoinWidget;
