import ResetPasswordForm from '@/features/auth/components/ResetPasswordForm';
import React, { useState } from 'react';
import { Paragraph, Button } from '@gourban/ui-components';
import { useSearchParams } from 'react-router-dom';
import restClient from '@/core/api/restClient';
import AuthenticationLayout from '@/features/auth/components/AuthenticationLayout';
import { Trans } from '@lingui/macro';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [passwordResetSuccessfully, setPasswordResetSuccessfully] = useState(false);

  const hasTarget = searchParams.has('target');

  const signIn = () => {
    const redirectUrl = new URL(`${restClient.defaults.baseURL!}/front/redirect/reset-password`);

    if (hasTarget) {
      redirectUrl.searchParams.set('target', searchParams.get('target')!);
    }

    window.location.href = redirectUrl.toString();
  };

  const onPasswordChangedSuccessfully = () => {
    setPasswordResetSuccessfully(true);
  };

  return (
    <AuthenticationLayout
      title={
        passwordResetSuccessfully ? (
          <Trans id="auth.resetPassword.success">Password changed!</Trans>
        ) : (
          <Trans id="auth.resetPassword.title">Set new password</Trans>
        )
      }
      form={
        passwordResetSuccessfully ? (
          <Paragraph marginBottom={24} align="center">
            <Trans id="auth.resetPassword.subtitle">Enter your new password.</Trans>
          </Paragraph>
        ) : (
          <>
            <Paragraph weight="thin" marginBottom={0}>
              <Trans id="auth.resetPassword.description">
                The password must be at least 8 characters long and contain at least 1 uppercase
                letter, 1 lowercase letter and 1 digit
              </Trans>
            </Paragraph>
            <ResetPasswordForm onPasswordChangedSuccessfully={onPasswordChangedSuccessfully} />
          </>
        )
      }
      footer={
        passwordResetSuccessfully && hasTarget ? (
          <Button onClick={signIn} variation="link" size="large">
            <Trans id="auth.resetPassword.login">Sign in</Trans>
          </Button>
        ) : null
      }
      alignCenter={passwordResetSuccessfully}
    />
  );
};

export default ResetPassword;
