import React, { FC, useState } from 'react';
import PaymentSources from '@/features/payment/components/PaymentSources';
import { BusinessAccount } from '@/features/businessAccounts/types';
import { t, Trans } from '@lingui/macro';
import Banner from '@/core/components/Banner';
import { useRequestPaymentSourcesQuery } from '@/features/payment/services/Payment.service';

interface PaymentStepT {
  businessAccount: BusinessAccount;
  onPaymentAdded: () => void;
}

const PaymentStep: FC<PaymentStepT> = ({ businessAccount, onPaymentAdded }) => {
  const [displayThankYouPage, setDisplayThankYouPage] = useState<boolean>(false);
  const { data: paymentSources } = useRequestPaymentSourcesQuery({
    userGroupId: businessAccount.id,
  });

  return (
    <>
      {!displayThankYouPage ? (
        <PaymentSources
          onPaymentMethodAdded={() => {
            setDisplayThankYouPage(true);
            onPaymentAdded();
          }}
          displayAddPaymentButton={!!paymentSources?.length}
          displayProviderForm={!paymentSources?.length}
          businessAccount={businessAccount}
        />
      ) : (
        <Banner
          icon="checkmark"
          iconColor="stroke-600"
          iconColorMode="stroke"
          header={t({ id: 'businessAccount.create.paymentSuccessTitle', message: 'Success' })}
          message={
            <Trans id="businessAccount.create.paymentSuccessDescription">
              Card successfully added.
            </Trans>
          }
        />
      )}
    </>
  );
};

export default PaymentStep;
