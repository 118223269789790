import { FC, useMemo } from 'react';
import { Loader, Tabs, Heading } from '@gourban/ui-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useVehicleCategories } from '@/features/myTrips/hooks/useVehicleCategories';
import {
  myActiveTrips,
  myUpcomingTrips,
  myPastTrips,
  myCanceledTrips,
} from '@/core/pages/MyTrips/routes';
import { Trans } from '@lingui/macro';
import FullScreen from '@/core/components/Layouts/FullScreen';
import WhiteLabelLogo from '@/core/components/UI/WhiteLabelLogo';
import UserAvatarDropdown from '@/core/components/UI/UserAvatarDropdown';

const MyTrips: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const categories = useVehicleCategories();

  const tabs = useMemo(
    () => [
      {
        ...myActiveTrips,
        name: <Trans id="myTrips.tabs.active">Active</Trans>,
        active:
          pathname === '/my-trips' || pathname === '/my-trips/' || pathname === '/my-trips/active',
      },
      {
        ...myUpcomingTrips,
        name: <Trans id="myTrips.tabs.upcoming">Upcoming</Trans>,
        active: pathname === '/my-trips/upcoming',
      },
      {
        ...myPastTrips,
        name: <Trans id="myTrips.tabs.past">Past</Trans>,
        active: pathname === '/my-trips/past',
      },
      {
        ...myCanceledTrips,
        name: <Trans id="myTrips.tabs.canceled">Canceled</Trans>,
        active: pathname === '/my-trips/canceled',
      },
    ],
    [pathname],
  );

  if (!categories) return <Loader cover />;

  return (
    <FullScreen
      onClose={() => navigate('/')}
      title={<WhiteLabelLogo />}
      actions={<UserAvatarDropdown />}
      mainSize="medium"
      main={
        <>
          <Heading marginBottom={24} size={4}>
            <Trans id="myTrips.title">My Trips</Trans>
          </Heading>
          <Tabs tabs={tabs} width={600} horizontal onClick={({ path }) => navigate(path)} />
          <Outlet />
        </>
      }
    />
  );
};

export default MyTrips;
