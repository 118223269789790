import { Button, Heading, Loader } from '@gourban/ui-components';
import { Form, FormikProvider, useFormik } from 'formik';
import BasicInfoForm from '@/features/account/components/Onboarding/BasicInfoForm';
import {
  useRequestMeDataQuery,
  useRequestUserUpdateMutation,
} from '@/features/account/services/Account.service';
import { useNavigate } from 'react-router-dom';
import { UserData } from '@/features/auth/types/User.types';
import { setStepComplete } from '@/features/account/redux/account.reducer';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { basicInfo, payment, verification } from '@/core/pages/Account/routes';
import { format } from 'date-fns';
import { useProfileSchema } from '@/features/auth/hooks/useProfileSchema';
import React, { FC } from 'react';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { SettingsKeys } from '@/core/enums';
import { formatPhoneNumber } from '@/core/utils/formatPhoneNumber';
import FullScreen from '@/core/components/Layouts/FullScreen';
import AccountStepper from '@/features/account/components/Onboarding/Stepper';
import OnboardingActionButtons from '@/features/account/components/Onboarding/OnboardingActionButtons';
import ResponsiveFixedButtons from '@/core/components/UI/ResponsiveFixedButtons';

const BasicInfo: FC = () => {
  const [updateUser] = useRequestUserUpdateMutation();
  const navigate = useNavigate();
  const { data: userData } = useRequestMeDataQuery();
  const dispatch = useDispatch();
  const { validationSchema, profile } = useProfileSchema();
  const loginSettings = useTypedSelector((state) => getSetting(state, SettingsKeys.LOGIN));

  const onUpdateUser = (values: Partial<UserData>) => {
    const { email, firstName, lastName, birthdate, street, city, postcode, phone, country } =
      values;

    return updateUser({
      ...values,
      email: profile.email.editable || !userData!.email ? email : undefined,
      firstName: profile.firstName.editable || !userData!.firstName ? firstName : undefined,
      lastName: profile.lastName.editable || !userData!.lastName ? lastName : undefined,
      street: profile.street.editable || !userData!.street ? street : undefined,
      city: profile.city.editable || !userData!.city ? city : undefined,
      country: profile.country.editable || !userData!.country ? country : undefined,
      phone: profile.phone.editable || !userData!.phone ? formatPhoneNumber(phone) : undefined,
      postcode: profile.postcode.editable || !userData!.postcode ? postcode : undefined,
      birthdate:
        birthdate && (profile.birthdate.editable || !userData!.birthdate)
          ? format(new Date(birthdate), 'yyyy-MM-dd')
          : undefined,
      customProperties: { ...(userData?.customProperties ?? {}), ...values.customProperties },
    })
      .unwrap()
      .then(() => {
        dispatch(setStepComplete({ name: 'basic-info', isComplete: true }));
        navigate(payment.path);
      });
  };

  const initialValues = {
    birthdate: userData?.birthdate ? new Date(userData?.birthdate) : undefined,
    customProperties: {
      birthPlace: userData?.customProperties?.birthPlace ?? '',
    },
    email: userData?.email,
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    street: userData?.street,
    city: userData?.city,
    postcode: userData?.postcode,
    country: userData?.country,
    phone: userData?.phone,
  };

  const basicInfoForm = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: onUpdateUser,
  });

  return (
    <FullScreen
      mainSize="narrow"
      title={
        <AccountStepper
          steps={[
            { stepName: 'basic-info', ...basicInfo },
            { stepName: 'payment', ...payment },
            { stepName: 'verification', ...verification },
          ]}
        />
      }
      actions={
        <ResponsiveFixedButtons>
          <OnboardingActionButtons
            nextButton={
              <Button
                disabled={!basicInfoForm.isValid}
                loading={basicInfoForm.isSubmitting}
                onClick={() => basicInfoForm.submitForm()}
              >
                <Trans id="general.next">Next</Trans>
              </Button>
            }
          />
        </ResponsiveFixedButtons>
      }
      main={
        <>
          <Heading marginBottom={16} weight="regular" size={4}>
            <Trans id="account.basicInfo.title">Basic info</Trans>
          </Heading>

          <FormikProvider value={basicInfoForm}>
            <Form>
              {basicInfoForm.isSubmitting && <Loader cover />}
              <BasicInfoForm signupType={loginSettings.phone ? 'PHONE' : 'EMAIL'} />
            </Form>
          </FormikProvider>
        </>
      }
    />
  );
};

export default BasicInfo;
