import { FC, ReactNode } from 'react';
import { Button, Heading, Modal, ModalComponent, Paragraph, Row } from '@gourban/ui-components';
import styles from '@/core/assets/scss/components/DiscardChangesModal.module.scss';

interface DiscardChangesModalT extends Pick<ModalComponent, 'opened'> {
  onCancel: () => void;
  onConfirm: () => void;
  heading?: ReactNode;
  description?: ReactNode;
  discardButtonLabel?: string;
  cancelButtonLabel?: string;
}

const DiscardChangesModal: FC<DiscardChangesModalT> = ({
  opened,
  onCancel,
  onConfirm,
  heading = 'Unsaved changes',
  description = 'Changes made will be lost if you exit the edit mode.',
  cancelButtonLabel = 'Cancel',
  discardButtonLabel = 'Discard',
}) => (
  <Modal
    onClose={onCancel}
    opened={opened}
    variation="small"
    overlayClassName={styles['discard-changes__overlay']}
    modalFooter={
      <Row justify="flex-end" className={styles['discard-changes__footer']}>
        <Button variation="secondary" onClick={onCancel}>
          {cancelButtonLabel}
        </Button>
        <Button variation="primary" onClick={onConfirm}>
          {discardButtonLabel}
        </Button>
      </Row>
    }
  >
    <Row>
      <Heading size={5}>{heading}</Heading>
    </Row>
    <Row>
      <Paragraph textColor="text-400">{description}</Paragraph>
    </Row>
  </Modal>
);

export default DiscardChangesModal;
