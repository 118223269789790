import React, { FC, memo } from 'react';
import { useRequestAvailableVehicleCategoriesQuery } from '@/features/vehicles/services/Vehicles.service';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import { Loader, Paragraph, Row } from '@gourban/ui-components';
import VehicleCategoryCard from '@/features/booking/components/VehicleCategorySelection/VehicleCategoryCard';
import { useTypedSelector } from '@/core/redux/hooks';
import { Trans } from '@lingui/macro';
import { getActiveFilterValues } from '@/features/account/redux/account.selectors';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';

const VehicleCategorySelection: FC<BookingFlowIDs> = ({ bookingId, branchId }) => {
  const { values } = useFormikContext<BookingOverviewForm>();
  const activeFilters = useTypedSelector(getActiveFilterValues);

  const { data: availableVehicleCategories, isFetching: isFetchingAvailableCategories } =
    useRequestAvailableVehicleCategoriesQuery({
      branchId,
      startDate: values.bookingDate?.from!.toISOString()!,
      endDate: values.bookingDate?.to
        ? values.bookingDate?.to.toISOString()
        : values.bookingDate?.from!.toISOString()!,
      startTime: values.bookingTime?.from!,
      endTime: values.bookingTime?.to!,
      vehicleAttributes: activeFilters?.vehicleAttributes,
      bookingId,
    });

  if (isFetchingAvailableCategories) {
    return <Loader cover />;
  }

  return (
    <div>
      <Paragraph marginBottom={24} weight="medium">
        <Trans id="bookings.createOrUpdate.selecteVehicleCategory">Select vehicle category</Trans>
      </Paragraph>

      <Row>
        {availableVehicleCategories?.length ? (
          availableVehicleCategories?.map((availableCategory) => {
            return (
              <VehicleCategoryCard key={availableCategory.id} categoryData={availableCategory} />
            );
          })
        ) : (
          <Paragraph size={4} textColor="text-400">
            <Trans id="bookings.createOrUpdate.noVehicleCategories">
              There are no vehicle categories available for selected time period. Some of the
              vehicles might only be partially available during this time. Try adjusting your time
              intervals.
            </Trans>
          </Paragraph>
        )}
      </Row>
    </div>
  );
};

export default memo(VehicleCategorySelection);
