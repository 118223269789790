import React, { FC, useMemo, memo } from 'react';
import { useRequestBusinessAccountMembershipQuery } from '@/features/businessAccounts/services/BusinessAccount.service';
import { Loader, Paragraph, Row } from '@gourban/ui-components';
import AccountSelectionCard from './AccountSelectionCard';
import { useRequestPaymentSourcesQuery } from '@/features/payment/services/Payment.service';
import { addToArrayConditionally } from '@/core/utils/addToArrayConditionally';
import { AvailablePaymentMethods } from '@/features/booking/types';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import { getPersonalPaymentName } from '@/features/booking/utils/getPersonalPaymentName';
import { Trans } from '@lingui/macro';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { TenantAccountSettings } from '@/features/geomap/types';
import { SettingsKeys } from '@/core/enums';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';

const AccountSelection: FC<Omit<BookingFlowIDs, 'branchId'>> = ({ bookingId }) => {
  const { data: businessAccounts } = useRequestBusinessAccountMembershipQuery();
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const { data: personalPayments, isLoading } = useRequestPaymentSourcesQuery({
    branchId: resolvedBranch!.id,
  });
  const tenantAccounts = useTypedSelector((state) =>
    getSetting<TenantAccountSettings>(state, SettingsKeys.TENANT_ACCOUNTS),
  );

  const isEdit = !!bookingId;

  const availablePaymentMethods = useMemo<AvailablePaymentMethods[]>(() => {
    const personalPaymentMethod = personalPayments?.[0];

    return [
      ...addToArrayConditionally<AvailablePaymentMethods>(!!personalPaymentMethod, {
        code: personalPaymentMethod?.id!,
        type: 'PERSONAL',
        name: getPersonalPaymentName(personalPaymentMethod),
      }),
      ...(businessAccounts && tenantAccounts?.businessAccounts?.active
        ? businessAccounts.map<AvailablePaymentMethods>((businessAcc) => ({
            ...businessAcc,
            type: 'BUSINESS',
          }))
        : []),
    ];
  }, [businessAccounts, personalPayments, tenantAccounts]);

  if (isLoading) {
    return <Loader cover />;
  }

  if (!availablePaymentMethods?.length) {
    return <AccountSelectionCard bookingId={bookingId} paymentData={null} />;
  }

  return (
    <Row>
      {availablePaymentMethods.map((paymentMethod) => (
        <AccountSelectionCard
          bookingId={bookingId}
          key={paymentMethod.code}
          paymentData={paymentMethod}
        />
      ))}

      {isEdit && availablePaymentMethods?.length > 1 && (
        <Paragraph size={4} textColor="warning">
          <Trans id="bookings.accountSelect.cannotModify">
            It is not possible to switch between personal and business bookings anymore once the
            booking has been confirmed. Please contact customer support if you need to make
            modifications.
          </Trans>
        </Paragraph>
      )}
    </Row>
  );
};

export default memo(AccountSelection);
