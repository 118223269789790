import React from 'react';
import cn from 'classnames';
import { groupBy } from 'lodash';
import { RouteStaticConfig } from '@/core/types';
import cssModules from '@/core/assets/scss/components/Dashboard/MainMenu.module.scss';
import { Caps } from '@gourban/ui-components';
import SubMenuItem from './SubMenuItem';
import AuthGuard from '@/features/auth/components/AuthGuard';
import { useLingui } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';

interface NavigationSubT {
  routes: RouteStaticConfig[];
}

const SubMenu: React.FC<NavigationSubT> = ({ routes }) => {
  const { i18n } = useLingui();
  const groupedRoutes = groupBy(routes, 'group.id');
  return (
    <>
      {Object.keys(groupedRoutes).map((group) => {
        const groupRoutes = groupedRoutes[group];
        const groupId = groupRoutes[0].group?.label;

        return (
          <React.Fragment key={groupId ?? 'no-group'}>
            {groupId && (
              <li
                className={cn([
                  cssModules['main-navigation__item'],
                  cssModules['main-navigation__item--submenu__group'],
                ])}
              >
                <Caps textColor="text-400">{groupId}</Caps>
              </li>
            )}
            {groupRoutes.map((route) => {
              const navItem = (
                <SubMenuItem
                  key={route.key}
                  to={route.path}
                  additionalPaths={route.additionalPaths}
                  content={
                    (route.label as MessageDescriptor).id
                      ? i18n._(route.label as MessageDescriptor)
                      : (route.label as string)
                  }
                />
              );

              return route.guarded ? (
                <AuthGuard key={route.key} fallbackBehavior="ignore">
                  {navItem}
                </AuthGuard>
              ) : (
                navItem
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default SubMenu;
