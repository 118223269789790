import React, { FC, ReactNode } from 'react';
import { Heading, Paragraph } from '@gourban/ui-components';
import styles from '../assets/scss/TenantError.module.scss';

interface TenantErrorT {
  description?: ReactNode;
  title?: ReactNode;
}

const TenantError: FC<TenantErrorT> = ({
  title = 'Not available',
  description = 'You are not allowed to access this app. Please contact your customer support for further information.',
}) => {
  return (
    <div className={styles['tenant-error']}>
      <div className={styles['tenant-error__content']}>
        <Heading marginBottom={12} align="center" size={3}>
          {title}
        </Heading>
        <Paragraph align="center">{description}</Paragraph>
      </div>
    </div>
  );
};

export default TenantError;
