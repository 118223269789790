import { FC, ReactNode } from 'react';
import { Card, Row, Column, Svg, Heading, Paragraph, Button } from '@gourban/ui-components';
import { useNavigate } from 'react-router-dom';
import styles from '@/features/myTrips/assets/scss/components/Trips/NoTrips.module.scss';
import { MyTripsListT } from '@/features/myTrips/components/Trips/MyTripsList';
import { Trans } from '@lingui/macro';

export const NoTrips: FC<MyTripsListT> = ({ view }) => {
  const navigate = useNavigate();

  let title: ReactNode;
  let subtitle: ReactNode;

  switch (view) {
    case 'ACTIVE':
      title = <Trans id="myTrips.noActiveTripsTitle">No active trips</Trans>;
      subtitle = <Trans id="myTrips.noActiveTripsSubtitle">You don't have active trips.</Trans>;
      break;
    case 'UPCOMING':
      title = <Trans id="myTrips.noUpcomingTripsTitle">No upcoming trips</Trans>;
      subtitle = <Trans id="myTrips.noUpcomingTripsSubtitle">You don't have upcoming trips.</Trans>;
      break;
    case 'CANCELED':
      title = <Trans id="myTrips.noCanceledTripsTitle">No canceled trips</Trans>;
      subtitle = <Trans id="myTrips.noCanceledTripsSubtitle">You don't have canceled trips.</Trans>;
      break;
    case 'PAST':
    default:
      title = <Trans id="myTrips.noPastTripsTitle">No past trips</Trans>;
      subtitle = <Trans id="myTrips.noPastTripsSubtitle">You don't have past trips.</Trans>;
      break;
  }

  return (
    <Card className={styles['no-trips']}>
      <Row
        column
        justify="center"
        alignItems="center"
        gapSm="sm"
        className={styles['no-trips--gap']}
      >
        <Column className={styles['no-trips__icon']}>
          <Svg icon="car" color="icon-300" width={32} height={32} />
        </Column>
        <Column>
          <Heading size={4}>{title}</Heading>
        </Column>
        <Column>
          <Paragraph>{subtitle}</Paragraph>
        </Column>
        <Column>
          <Paragraph>
            <Trans id="myTrips.cta">Book a vehicle!</Trans>
          </Paragraph>
        </Column>
        <Column>
          <Button onClick={() => navigate('/')}>
            <Trans id="myTrips.action">Explore</Trans>
          </Button>
        </Column>
      </Row>
    </Card>
  );
};
