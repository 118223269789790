/* eslint-disable */
import React, { useCallback, useMemo, useRef, memo, FC, useEffect } from 'react';
import {
  Button,
  Column,
  Paragraph,
  Row,
  Separator,
  DatePickerCalendar,
  TimePickerIntervals,
  Loader,
} from '@gourban/ui-components';
import styles from '../../assets/scss/components/DateSelect.module.scss';
import { addDays, addHours, format, isAfter, isBefore, isPast, max, min } from 'date-fns';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { useFormikContext } from 'formik';
import { BookingOverviewForm, StepRules } from '@/features/booking/types';
import { useRequestBookingAvailabilityQuery } from '@/features/booking/services/Booking.service';
import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { useRequestUserTermsAndConditionsStatusQuery } from '@/features/account/services/Account.service';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { useTypedSelector } from '@/core/redux/hooks';
import { getActiveFilterValues } from '@/features/account/redux/account.selectors';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { DateFormats } from '@/core/enums';
import { useResponsive } from '@/core/hooks/useResponsive';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';
import { DateRange } from '@/core/types';
import { addToArrayConditionally } from '@/core/utils/addToArrayConditionally';

const DateSelect: FC<Partial<BookingFlowIDs> & { rules?: StepRules['date'] }> = ({
  bookingId,
  branchId,
  rules,
}) => {
  const activeFilters = useTypedSelector(getActiveFilterValues);
  const { values, setFieldValue, errors, touched, initialValues } =
    useFormikContext<BookingOverviewForm>();
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const { data: branchData } = useRequestSingleBranchQuery(branchId!, { skip: !branchId });
  const { i18n } = useLingui();
  const { isMobile } = useResponsive();
  const { data: termsAndConditions } = useRequestUserTermsAndConditionsStatusQuery(branchId!, {
    skip: !branchId,
  });
  const { data: bookingAvailabilityDates, isFetching: isFetchingBookingAvailability } =
    useRequestBookingAvailabilityQuery(
      {
        branchId: branchId!,
        maxDateRange: bookingSettings?.value.properties.maxDateRange,
        vehicleAttributes: activeFilters?.vehicleAttributes,
        bookingId,
      },
      {
        skip:
          !branchData || !bookingSettings || !branchId || termsAndConditions?.state !== 'ACCEPTED',
      },
    );
  const dateLocale = useDateLocale();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const unavailableDates = useMemo(() => {
    if (!bookingAvailabilityDates || !branchId) return [];

    return bookingAvailabilityDates
      .filter((booking) => booking.availableState === 'UNAVAILABLE')
      .map((booking) => booking.date);
  }, [bookingAvailabilityDates, branchId]);

  const partiallyAvailableDates = useMemo(() => {
    if (!bookingAvailabilityDates || !branchId) return [];

    return bookingAvailabilityDates
      .filter((booking) => booking.availableState === 'PARTIALLY_AVAILABLE')
      .map((booking) => new Date(booking.date));
  }, [bookingAvailabilityDates, branchId]);

  const chooseDuration = (hours: number, customFromTime?: string) => {
    const defaultTime = formatInitialTime(format(new Date(), 'HH:mm'), 0);
    const fromDate = values.bookingDate?.from ? new Date(values.bookingDate.from) : new Date();
    const fromTime = customFromTime ?? values.bookingTime?.from ?? defaultTime;

    void setFieldValue('bookingTime', {
      from: fromTime,
      to: format(new Date(addHours(mergeDateTime(fromDate, fromTime), hours)), 'HH:mm'),
    });

    void setFieldValue('bookingDate', {
      from: fromDate,
      to: addHours(mergeDateTime(fromDate, fromTime), hours),
    });
  };

  const durationButtonDisabled = useMemo(() => {
    const fromDate = values.bookingDate?.from ? new Date(values.bookingDate.from) : new Date();

    const checkIfShouldBeDisabled = (numberOfHours: number) => {
      const toBeDate = addHours(fromDate, numberOfHours);

      return (
        unavailableDates.includes(format(toBeDate, 'yyyy-MM-dd')) || isBefore(toBeDate, new Date())
      );
    };

    return {
      '1-hour': checkIfShouldBeDisabled(1),
      '3-hour': checkIfShouldBeDisabled(3),
      '5-hour': checkIfShouldBeDisabled(5),
      '1-day': checkIfShouldBeDisabled(24),
    };
  }, [values.bookingDate?.from, unavailableDates, values.bookingTime?.from]);

  const shouldBeDisabled = useCallback(
    (value: string | null, datePeriod: 'from' | 'to') => {
      const { bookingTime, bookingDate } = values;

      if (!bookingTime || !value) {
        return false;
      }

      const dateReference =
        datePeriod === 'from'
          ? bookingDate?.from ?? new Date()
          : bookingDate?.to ?? bookingDate?.from ?? new Date();

      const dateTime = mergeDateTime(dateReference, value);

      const minStartTime = max([new Date()]);
      const maxStartTime = min([
        mergeDateTime(bookingDate?.to ?? bookingDate?.from ?? new Date(), bookingTime?.to),
      ]);

      const minEndTime = max([
        mergeDateTime(bookingDate?.from ?? new Date(), bookingTime?.from),
        ...addToArrayConditionally(!!rules?.minDate, new Date(rules?.minDate!)),
      ]);
      const maxEndTime = min([
        ...addToArrayConditionally(!!rules?.maxDate, new Date(rules?.maxDate!)),
      ]);

      if (datePeriod === 'from') {
        return (
          isBefore(dateTime, minStartTime) ||
          format(dateTime, DateFormats.FULL_DATE_TIME) ===
            format(maxStartTime, DateFormats.FULL_DATE_TIME) ||
          isAfter(dateTime, maxStartTime)
        );
      }

      return (
        isBefore(dateTime, minEndTime) ||
        format(dateTime, DateFormats.FULL_DATE_TIME) ===
          format(minEndTime, DateFormats.FULL_DATE_TIME) ||
        isAfter(dateTime, maxEndTime)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, rules],
  );

  useEffect(() => {
    const defaultStartTime = formatInitialTime(
      bookingSettings?.value?.properties?.preselectedStartingTime,
      60,
      bookingSettings?.value?.properties?.timeGap,
    );

    const defaultEndTime = formatInitialTime(
      bookingSettings?.value?.properties?.preselectedEndingTime,
      120,
      bookingSettings?.value?.properties?.timeGap,
    );

    if (!values?.bookingDate?.from) {
      void setFieldValue('bookingTime.from', defaultStartTime);
      void setFieldValue('bookingTime.to', defaultEndTime);
      return;
    }

    // We need to resolve cases where future day is selected as well as time, and changing date in the past would make time invalid, so we need to reset it
    if (
      values.bookingDate.from !== initialValues.bookingDate?.from &&
      isPast(values.bookingDate.from)
    ) {
      void setFieldValue('bookingTime.from', defaultStartTime);
      void setFieldValue(
        'bookingDate.from',
        mergeDateTime(values.bookingDate.from, defaultStartTime),
      );
      return;
    }

    if (rules?.maxDate && values.bookingDate.to) {
      const maxDate = new Date(rules.maxDate);

      if (isAfter(values.bookingDate.to, maxDate)) {
        void setFieldValue('bookingDate.to', maxDate);
        void setFieldValue('bookingTime.to', format(maxDate, 'HH:mm'));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bookingDate]);

  return (
    <div ref={containerRef} className={styles['date-select']}>
      {isFetchingBookingAvailability && <Loader cover />}
      <Row marginBottom={0} alignItems="center" gapSm="xs" justify="space-between">
        <Column sm={12} md={6}>
          <Paragraph marginBottom={10}>
            <Trans id="bookings.filters.selectDate">Select date</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} size={4} textColor="text-400">
            {values.bookingDate?.from ? (
              `${format(values.bookingDate?.from, DateFormats.SHORT_DATE, {
                locale: dateLocale,
              })} - ${format(
                values.bookingDate?.to ?? values.bookingDate?.from,
                DateFormats.SHORT_DATE,
                { locale: dateLocale },
              )}`
            ) : (
              <Trans id="bookings.filters.noDateSelected">No date selected</Trans>
            )}
          </Paragraph>
        </Column>
        <Column sm={12} md={6}>
          <Row marginBottom={0} alignItems="center" gapSm="xs" justify="space-between">
            <Column className={styles['date-select__from']} sm={6}>
              <TimePickerIntervals
                fieldAttr={{
                  id: 'from',
                  required: true,
                  disabled: rules?.disableStartTimeSelection,
                }}
                fieldProps={{
                  label: t({ id: 'bookings.filters.from', message: 'From' }),
                  size: 'small',
                  iconLeft: 'clock',
                  timeInterval: (bookingSettings?.value?.properties?.timeGap as number) ?? 30,
                  isDisabled: (val) => shouldBeDisabled(val!, 'from'),
                  onChange: (selectedTime) => {
                    void setFieldValue(
                      'bookingDate.from',
                      mergeDateTime(values.bookingDate?.from ?? new Date(), selectedTime),
                    );
                  },
                  boundingBox: containerRef,
                }}
                name="bookingTime.from"
              />
            </Column>
            <Column sm={6}>
              <TimePickerIntervals
                fieldAttr={{ id: 'to', required: true }}
                fieldProps={{
                  label: t({ id: 'bookings.filters.to', message: 'Until' }),
                  size: 'small',
                  iconLeft: 'clock',
                  timeInterval: (bookingSettings?.value?.properties?.timeGap as number) ?? 30,
                  isDisabled: (val) => {
                    return shouldBeDisabled(val!, 'to');
                  },
                  onChange: (selectedTime) => {
                    if (values.bookingDate?.from || values.bookingDate?.to) {
                      void setFieldValue(
                        'bookingDate.to',
                        mergeDateTime(
                          values.bookingDate?.to ?? values.bookingDate?.from!,
                          selectedTime,
                        ),
                      );
                    }
                  },
                  boundingBox: containerRef,
                }}
                name="bookingTime.to"
              />
            </Column>
            {errors.bookingTime &&
              (touched.bookingTime as BookingOverviewForm['bookingTime'])?.from &&
              (touched.bookingTime as BookingOverviewForm['bookingTime'])?.to && (
                <Column sm={12}>
                  <Paragraph marginBottom={0} size={4} textColor="error">
                    {errors.bookingTime}
                  </Paragraph>
                </Column>
              )}
          </Row>
        </Column>
      </Row>

      <div className={styles['date-select__calendar']}>
        <DatePickerCalendar
          fieldProps={{
            isRange: true,
            allowableSelectionRange: {
              minDate: rules?.minDate ?? new Date().toString(),
              maxDate:
                rules?.maxDate ??
                addDays(
                  new Date(),
                  bookingSettings?.value.properties.maxDateRange ?? 365,
                ).toString(),
            },
            onChange: (dateRange) => {
              // This is an edge case where user is selecting current date, he should be able to go back to it
              if (rules?.preventSelectionCancel && !dateRange) {
                void setFieldValue('bookingDate', {
                  from: initialValues?.bookingDate?.from,
                  to: initialValues?.bookingDate?.to,
                });

                return;
              }

              // This means that date range cannot be cleared, mainly used when extending booking
              // We dont want to allow the user the clear the range of booking that is already there, just extend it
              if (rules?.preventSelectionCancel && !(dateRange as DateRange)?.to) {
                void setFieldValue('bookingDate', {
                  from: values?.bookingDate?.from,
                  to: values?.bookingDate?.to,
                });

                return;
              }

              if (dateRange) {
                // We need to include time in these selected dates
                void setFieldValue('bookingDate', {
                  from: mergeDateTime((dateRange as DateRange)?.from, values.bookingTime?.from),
                  to:
                    (dateRange as DateRange)?.to &&
                    mergeDateTime((dateRange as DateRange)?.to, values.bookingTime?.to),
                });
              }
            },
            disabledDates: unavailableDates,
            markedDates: partiallyAvailableDates,
            markedDateColor: 'var(--y-400)',
            numberOfMonths: isMobile ? 1 : 2,
          }}
          name="bookingDate"
        />
      </div>

      {!rules?.hidePresets && (
        <>
          <Separator gapBottom={16} gapTop={16} />

          <Paragraph marginBottom={4} size={4}>
            <Trans id="bookings.filters.duration">Duration</Trans>
          </Paragraph>
          <Row marginBottom={0} gapSm="xs">
            <Column>
              <Button
                disabled={durationButtonDisabled['1-hour']}
                onClick={() => chooseDuration(1)}
                size="small"
                variation="secondary"
              >
                {i18n.number(1, { style: 'unit', unit: 'hour', unitDisplay: 'long' })}
              </Button>
            </Column>
            <Column>
              <Button
                disabled={durationButtonDisabled['3-hour']}
                onClick={() => chooseDuration(3)}
                size="small"
                variation="secondary"
              >
                {i18n.number(3, { style: 'unit', unit: 'hour', unitDisplay: 'long' })}
              </Button>
            </Column>
            <Column>
              <Button
                disabled={durationButtonDisabled['5-hour']}
                onClick={() => chooseDuration(5)}
                size="small"
                variation="secondary"
              >
                {i18n.number(5, { style: 'unit', unit: 'hour', unitDisplay: 'long' })}
              </Button>
            </Column>
            <Column>
              <Button
                disabled={durationButtonDisabled['1-day']}
                onClick={() => chooseDuration(24)}
                size="small"
                variation="secondary"
              >
                {i18n.number(1, { style: 'unit', unit: 'day', unitDisplay: 'long' })}
              </Button>
            </Column>
          </Row>
        </>
      )}
    </div>
  );
};

export default memo(DateSelect);
