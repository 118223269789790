import { FC, PropsWithChildren } from 'react';
import styles from '@/core/assets/scss/components/Layouts/UnauthLayout.module.scss';
import { Column, Paragraph, Row, useGoUrbanConfigContext } from '@gourban/ui-components';
import LangPicker from '@/features/auth/components/LangPicker';
import {
  AvailableLocales,
  LocalStorageKeys,
  SettingsKeys,
  WhiteLabelingAPIKeys,
} from '@/core/enums';
import { activateLocale } from '@/core/utils/activateLocale';
import { useTypedDispatch, useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { AppWhiteLabel } from '@/features/booking/types';
import {
  BranchesService,
  useRequestResolvedBranchQuery,
} from '@/features/branches/services/Branches.service';
import { BranchAPIKeys } from '@/features/branches/enums';
import {
  useRequestWhitelabelAppQuery,
  WhitelabelService,
} from '@/core/services/WhiteLabeling.service';
import MarkdownParser from '@/core/components/MarkdownParser';
import WhiteLabelLogo from '@/core/components/UI/WhiteLabelLogo';

interface UnauthLayoutT {
  hideHeader?: boolean;
  hideFooter?: boolean;
}

const UnauthLayout: FC<PropsWithChildren<UnauthLayoutT>> = ({
  children,
  hideHeader,
  hideFooter,
}) => {
  const dispatch = useTypedDispatch();
  const { setConfig } = useGoUrbanConfigContext();
  const whiteLabelSettings = useTypedSelector((state) =>
    getSetting<AppWhiteLabel>(state, SettingsKeys.WHITELABEL_APP),
  );
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const { data: whiteLabelApp } = useRequestWhitelabelAppQuery();

  const onLangSelected = (lang: AvailableLocales) => {
    localStorage.setItem(LocalStorageKeys.PREFERRED_LANG, lang);
    void activateLocale(lang);
    setConfig!((prev) => ({ ...prev, locale: lang }));
    dispatch(
      BranchesService.util.invalidateTags([
        { type: BranchAPIKeys.BRANCH_TERMS_AND_CONDITIONS, id: resolvedBranch?.id },
      ]),
    );
    dispatch(WhitelabelService.util.invalidateTags([WhiteLabelingAPIKeys.WHITE_LABEL]));
  };

  const copyright = whiteLabelSettings?.web?.copyright ?? '© Copyright 2024 - Wunder mobility';

  return (
    <div className={styles.unauth__layout}>
      <div className={styles.unauth__layout__content}>
        {!hideHeader && (
          <header className={styles['unauth__layout__content-header']}>
            <WhiteLabelLogo type="big" />
            <LangPicker onLangSelected={onLangSelected} />
          </header>
        )}
        {hideHeader && (
          <Row justify="flex-end">
            <LangPicker onLangSelected={onLangSelected} />
          </Row>
        )}

        <main className={styles['unauth__layout__content-main']}>{children}</main>

        {!hideFooter && (
          <footer className={styles['unauth__layout__content-footer']}>
            <Row alignItems="flex-end" gapSm="sm" justify="space-between">
              <Column>
                <Paragraph size={4} marginBottom={0}>
                  {copyright}
                </Paragraph>
              </Column>
              <Column>
                <Row className={styles['unauth__layout__content-links']} gapSm="xs">
                  {whiteLabelApp?.web?.footer?.text && (
                    <MarkdownParser size={4} markdown={whiteLabelApp.web.footer.text} />
                  )}
                </Row>
              </Column>
            </Row>
          </footer>
        )}
      </div>
    </div>
  );
};

export default UnauthLayout;
