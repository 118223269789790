import fallbackModules from '@/core/assets/scss/components/ErrorHandlers/ErrorFallback.module.scss';
import { Button, Heading, Svg, Paragraph, Spacer } from '@gourban/ui-components';
import { FC, ReactNode } from 'react';
import { Trans } from '@lingui/macro';

interface ErrorFallbackT {
  message?: ReactNode;
}

const ErrorFallback: FC<ErrorFallbackT> = ({
  message = (
    <Trans id="errorFallback.defaultMessage">Try again or contact support for assistance.</Trans>
  ),
}) => {
  /**
   * Navigate user back to root to see if he can login again
   */
  const onTryAgain = () => {
    window.location.href = `${window.location.href}`;
  };

  return (
    <div className={fallbackModules.fallback}>
      <div className={fallbackModules.fallback__content}>
        <Svg width={40} icon="triangleWarning" />
        <Spacer height={10} />
        <Heading size={4}>Something went wrong.</Heading>
        <Paragraph align="center">{message}</Paragraph>
        <Button size="small" onClick={onTryAgain} label="Try again" />
      </div>
    </div>
  );
};

export default ErrorFallback;
