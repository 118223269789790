import React, { FC, useEffect, useState, memo } from 'react';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import { useTypedSelector } from '@/core/redux/hooks';
import { getVehicleCategoryData } from '@/features/vehicles/redux/Vehicles.selector';
import styles from '@/features/booking/assets/scss/components/VehicleCategorySummary.module.scss';
import { Alert, Button, Heading, Loader, Separator } from '@gourban/ui-components';
import VehicleCategoryDescription from '@/features/vehicles/components/VehicleCategoryDescription';
import { useRequestVehicleCategoryRequirementsQuery } from '@/features/vehicles/services/Vehicles.service';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { t, Trans } from '@lingui/macro';
import RentalRequirementsList from '@/features/booking/components/VehicleCategorySummary/RentalRequirementsList';
import LocationGuidelines from '@/features/booking/components/LocationGuidelines';
import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';

const VehicleCategorySummary: FC<Omit<BookingFlowIDs, 'bookingId'>> = ({ branchId }) => {
  const [viewRentalRequirements, setViewRentalRequirements] = useState(false);
  const { data: stationData } = useRequestSingleBranchQuery(branchId);
  const { values, setFieldValue } = useFormikContext<BookingOverviewForm>();
  const vehicleCategory = useTypedSelector((state) =>
    getVehicleCategoryData(state, values.selectedVehicleCategory),
  );
  const { data: rentalRequirements, isFetching: isFetchingRentalRequirements } =
    useRequestVehicleCategoryRequirementsQuery({
      branchId,
      vehicleCategoryId: values.selectedVehicleCategory!,
      bookingStartTime: new Date(
        mergeDateTime(values.bookingDate!.from!, values.bookingTime?.from),
      ).toISOString(),
      bookingEndTime: new Date(
        mergeDateTime(values.bookingDate!.to ?? values.bookingDate!.from!, values.bookingTime?.to),
      ).toISOString(),
      userGroupCode:
        values.selectedPaymentType === 'BUSINESS' ? values.selectedPaymentMethod : undefined,
    });

  useEffect(() => {
    if (rentalRequirements?.length === 0) {
      void setFieldValue('rentalRequirementsFulfilled', true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentalRequirements]);

  if (isFetchingRentalRequirements) {
    return <Loader cover />;
  }

  if (viewRentalRequirements) {
    return <RentalRequirementsList rentalRequirements={rentalRequirements!} />;
  }

  return (
    <div className={styles.summary}>
      <div className={styles.summary__logo}>
        <img src={vehicleCategory?.appProperties?.image} alt={vehicleCategory!.name} />
      </div>
      <Heading align="center" size={4}>
        {vehicleCategory!.name}
      </Heading>
      <Separator gapBottom={24} gapTop={24} />

      {!!rentalRequirements?.length && (
        <Alert
          icon="info"
          type="info"
          full
          canBeClosed={false}
          title={t({
            id: 'booking.rentalRequirements.title',
            message: 'Requirements not fulfilled',
          })}
          description={
            <>
              <Trans id="booking.rentalRequirements.description">
                View the list of requirements you need to fulfil so you can rent this vehicle.
              </Trans>
              <br />
              <Button
                className={styles['summary__rental-requirements-view']}
                onClick={() => setViewRentalRequirements(true)}
                size="small"
              >
                <Trans id="general.view">View</Trans>
              </Button>
            </>
          }
        />
      )}

      <ul className={styles.summary__list}>
        {vehicleCategory!.sharedProperties?.vehicleCategoryFeatures?.map((feature) => (
          <li key={feature.title}>{feature.title}</li>
        ))}
      </ul>

      <VehicleCategoryDescription description={vehicleCategory?.description} />
      <LocationGuidelines locationGuidelines={stationData?.contactInfo?.locationGuidelines} />
    </div>
  );
};

export default memo(VehicleCategorySummary);
