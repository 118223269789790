import { useEffect, useState, useCallback, FC } from 'react';
import ReactDOM from 'react-dom';
import { ErrorHandlerSingleError, ErrorRegistration, ValidationError } from '@/core/types';
import { Button, Column, Heading, Modal, Paragraph, Row, Svg } from '@gourban/ui-components';
import { Method } from 'axios';
import styles from '@/core/assets/scss/components/ErrorHandlers/ErrorHandlingModal.module.scss';
import { capitalize } from 'lodash';
import { t, Trans } from '@lingui/macro';
import TraceIdCollapsable from '@/core/components/ErrorHandlers/TraceIdCollapsable';

class ErrorHandlingProvider {
  constructor() {
    this.createErrorHandler = () => {};
  }

  private createErrorHandler: (error: ErrorHandlerSingleError) => void;

  public createError(error: ErrorHandlerSingleError) {
    return this.createErrorHandler(error);
  }

  public register({ createError }: ErrorRegistration) {
    this.createErrorHandler = createError;
  }
}

/**
 * Create and instance of ErrorHandling store where new errors are created/stored
 */
const store = new ErrorHandlingProvider();

const ErrorHandlerProvider: FC = () => {
  const [error, setError] = useState<ErrorHandlerSingleError | null>(null);

  const createError = useCallback((errorObj: ErrorHandlerSingleError) => {
    setError(errorObj);
  }, []);

  useEffect(() => {
    store.register({ createError });
  }, [createError]);

  const renderStringBasedOnMethod = useCallback((method: Method) => {
    switch (method) {
      case 'delete':
      case 'DELETE':
        return t({ id: 'general.notification.deleteFailed', message: 'Delete failed' });
      case 'put':
      case 'PUT':
      case 'patch':
      case 'PATCH':
        return t({ id: 'general.notification.updateFailed', message: 'Update failed' });
      case 'post':
      case 'POST':
        return t({ id: 'general.notification.creationFailed', message: 'Creation failed' });
      default:
        return t({ id: 'general.notification.errorOccured', message: 'An error has occured' });
    }
  }, []);

  const renderErrorModal = useCallback((errorObj: ErrorHandlerSingleError | null) => {
    return (
      <Modal
        portalClassName={styles['error-handling-modal']}
        variation="small"
        modalFooter={
          <Row justify="center">
            <Button onClick={() => setError(null)}>
              <Trans id="general.continue">Continue</Trans>
            </Button>
          </Row>
        }
        onClose={() => setError(null)}
        opened={!!errorObj}
      >
        <Row justify="center">
          <Svg icon="info" width={40} color="error" />
        </Row>
        <Heading marginBottom={30} align="center" size={4}>
          {renderStringBasedOnMethod(errorObj?.requestConfig?.method as Method)}
        </Heading>

        <Row noWrap marginBottom={0} gapSm="sm">
          <Column marginBottom={0}>
            <Svg color="error" width={20} height={18} icon="warningFilled" />
          </Column>
          <Column grow marginBottom={0}>
            <Paragraph marginBottom={4} textColor="text-600" weight="light" size={4}>
              {capitalize(
                errorObj?.error?.userMessage ||
                  errorObj?.error?.errorMessage ||
                  t({ id: 'general.notification.errorOccured', message: 'An error has occured' }),
              )}
            </Paragraph>

            {(errorObj?.error?.validation || errorObj?.error?.details) && (
              <>
                <Paragraph size={4} marginBottom={4}>
                  <Trans id="general.notification.reason">Reason:</Trans>
                </Paragraph>
                {(errorObj?.error?.validation || errorObj?.error?.details) &&
                  (errorObj?.error?.validation || errorObj?.error?.details)?.map(
                    (validationErrors: ValidationError) => {
                      return (
                        <Paragraph
                          marginBottom={0}
                          textColor="error"
                          size={5}
                          key={validationErrors?.field ?? validationErrors?.name}
                        >
                          &nbsp;&nbsp; -{' '}
                          {validationErrors?.message ?? validationErrors?.userMessage}
                        </Paragraph>
                      );
                    },
                  )}
              </>
            )}
          </Column>
        </Row>
        <TraceIdCollapsable errorResponse={errorObj?.error} />
      </Modal>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(renderErrorModal(error), document.getElementsByTagName('body')[0]);
};

export { store as ErrorHandler };
export default ErrorHandlerProvider;
