import { FC } from 'react';
import { Button, Card, Column, Paragraph, Row } from '@gourban/ui-components';
import { AvailablePaymentMethods, BookingOverviewForm } from '@/features/booking/types';
import styles from '../../assets/scss/components/AccountSelectionCard.module.scss';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import { Trans } from '@lingui/macro';
import BusinessTripReason from '@/features/booking/components/AccountSelection/BusinessTripReason';
import { useNavigate } from 'react-router-dom';
import { accountEditPaymentMethods } from '@/core/pages/Account/routes';
import { BookingFlowIDs } from '../BookingFlow/BookingFlow';

interface PaymentSelectionCardT extends Pick<BookingFlowIDs, 'bookingId'> {
  paymentData: AvailablePaymentMethods | null;
}

const AccountSelectionCard: FC<PaymentSelectionCardT> = ({ paymentData, bookingId }) => {
  const { values, setFieldValue } = useFormikContext<BookingOverviewForm>();
  const navigate = useNavigate();

  const isEdit = !!bookingId;

  if (!paymentData) {
    return (
      <Column sm={12}>
        <Card
          className={cn(
            styles['account-selection-card'],
            styles['account-selection-card--inactive'],
          )}
        >
          <Button
            className={styles['account-selection-card__complete-profile']}
            iconName="arrowRight"
            variation="link"
            size="small"
            onClick={() => navigate(accountEditPaymentMethods.path)}
          />
          <Row gapSm="sm" justify="space-between">
            <Column sm={8}>
              <Paragraph size={4} textColor="text-400" marginBottom={8} weight="medium">
                <Trans id="bookings.accountSelect.personalTrip">Personal trip</Trans>
              </Paragraph>

              <Paragraph marginBottom={0} weight="medium">
                <Trans id="bookings.accountSelect.completeProfile">Complete profile</Trans>
              </Paragraph>
            </Column>
          </Row>
        </Card>
      </Column>
    );
  }

  const isBusinessTrip = paymentData.type === 'BUSINESS';

  return (
    <Column sm={12}>
      <Card
        className={cn(
          styles['account-selection-card'],
          values.selectedPaymentType === paymentData.type &&
            styles['account-selection-card--selected'],
          isEdit && styles['account-selection-card--disabled'],
        )}
        onClick={() => {
          if (isEdit) return;

          void setFieldValue('selectedPaymentMethod', paymentData.code);
          void setFieldValue('selectedPaymentType', paymentData.type);
        }}
      >
        <span
          aria-disabled={isEdit}
          data-testid="SelectionCardIndication"
          className={styles['account-selection-card__indication']}
        />
        <Row gapSm="sm" justify="space-between">
          <Column sm={8}>
            <Paragraph size={4} textColor="text-400" marginBottom={8} weight="medium">
              {isBusinessTrip ? (
                <Trans id="bookings.accountSelect.businessTrip">Business trip</Trans>
              ) : (
                <Trans id="bookings.accountSelect.personalTrip">Personal trip</Trans>
              )}
            </Paragraph>

            <Paragraph marginBottom={0} weight="medium">
              {paymentData.name}
            </Paragraph>
          </Column>
          {isBusinessTrip && values.selectedPaymentType === 'BUSINESS' && (
            <BusinessTripReason isEdit={isEdit} />
          )}
        </Row>
      </Card>
    </Column>
  );
};

export default AccountSelectionCard;
