import React, { FC, ReactNode, useEffect } from 'react';
import { useTypedSelector } from '@/core/redux/hooks';
import { getIsLoggedIn } from '@/features/auth/redux/authorization.selectors';
import { Loader, Paragraph } from '@gourban/ui-components';
import {
  useRequestBusinessAccountMembershipQuery,
  useRequestJoinBusinessAccountMutation,
  useRequestJoinBusinessAccountRequestsQuery,
} from '@/features/businessAccounts/services/BusinessAccount.service';
import AuthGuard from '@/features/auth/components/AuthGuard';
import { t, Trans } from '@lingui/macro';
import { ApiError } from '@/core/types';
import { LocalStorageKeys } from '@/core/enums';
import { addMinutes } from 'date-fns';
import { capitalize } from 'lodash';
import Banner from '@/core/components/Banner';
import { BusinessAccountCacheTags } from '@/features/businessAccounts/enums';

interface JoinApplicationT {
  code: string;
  action?: ReactNode;
}

const JoinApplication: FC<JoinApplicationT> = ({ code, action }) => {
  const isLoggedIn = useTypedSelector(getIsLoggedIn);
  const { data: businessAccountMembership, isLoading: isLoadingBusinessAccount } =
    useRequestBusinessAccountMembershipQuery(undefined, {
      skip: !isLoggedIn,
    });
  const { data: businessAccountJoinRequests } = useRequestJoinBusinessAccountRequestsQuery(
    undefined,
    { skip: !isLoggedIn },
  );

  const [
    joinBusinessAccount,
    { data: joinRequestData, isLoading: isJoiningBusinessAccount, isError, error },
  ] = useRequestJoinBusinessAccountMutation({
    fixedCacheKey: BusinessAccountCacheTags.JOIN_REQUESTS,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      localStorage.setItem(
        LocalStorageKeys.JOIN_APPLICATION_CODE,
        JSON.stringify({
          code,
          expTime: addMinutes(new Date(), 15),
        }),
      );

      return;
    }

    if (businessAccountMembership?.length || businessAccountJoinRequests?.length) {
      localStorage.removeItem(LocalStorageKeys.JOIN_APPLICATION_CODE);
    }

    if (businessAccountMembership?.length === 0 && businessAccountJoinRequests?.length === 0) {
      joinBusinessAccount(code)
        .unwrap()
        .finally(() => {
          localStorage.removeItem(LocalStorageKeys.JOIN_APPLICATION_CODE);
        });
    }
  }, [
    isLoggedIn,
    businessAccountMembership,
    code,
    joinBusinessAccount,
    businessAccountJoinRequests,
  ]);

  if (isLoadingBusinessAccount || isJoiningBusinessAccount) {
    return <Loader fixed cover />;
  }

  if (joinRequestData) {
    return (
      <AuthGuard>
        <Banner
          header={t({
            id: 'businessAccount.joinApplication.success',
            message: 'Request successful',
          })}
          iconColorMode="stroke"
          icon="checkmark"
          iconColor="stroke-600"
          message={
            <>
              <Paragraph marginBottom={32} align="center">
                <Trans id="businessAccount.joinApplication.successDescription">
                  Your request to join business account is successfully sent!
                  <br />
                  We will notify you in the email when your application is answered!
                </Trans>
              </Paragraph>
              {action}
            </>
          }
        />
      </AuthGuard>
    );
  }

  return (
    <AuthGuard>
      {((isLoggedIn &&
        (businessAccountMembership?.length || businessAccountJoinRequests?.length)) ||
        isError) && (
        <Banner
          icon="close"
          iconColor="icon-600"
          header={t({ id: 'businessAccount.joinApplication.fail', message: 'Request failed' })}
          message={
            <>
              <Paragraph marginBottom={32} align="center">
                {capitalize((error as ApiError)?.userMessage) || (
                  <Trans id="businessAccount.joinApplication.failDescription">
                    Join application failed.
                    <br />
                    You are already a member of another business account
                    <br />
                    or you have a pending request to join another business account
                  </Trans>
                )}
              </Paragraph>
              {action}
            </>
          }
        />
      )}
    </AuthGuard>
  );
};

export default JoinApplication;
