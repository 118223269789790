import { Form, Formik } from 'formik';
import { t, Trans } from '@lingui/macro';
import { FC } from 'react';
import { Alert, Button, Paragraph, Row } from '@gourban/ui-components';
import CodeInput from '@/features/auth/components/CodeInput';
import { ApiError } from '@/core/types';
import { PhoneLoginFormValues } from '@/features/auth/components/PhoneLoginForm';
import { useCountdown } from '@/features/auth/hooks/useCountdown';

export interface PhoneLoginVerificationFormProps {
  phone: string;
  onResendCode: (values: PhoneLoginFormValues) => void;
  login: (code: string) => void;
  error?: ApiError;
  countdown?: number;
}

const PhoneLoginVerificationForm: FC<PhoneLoginVerificationFormProps> = ({
  phone,
  onResendCode,
  login,
  error,
  countdown = 0,
}) => {
  const { countdown: currentCountdown, setCountdown } = useCountdown(countdown);

  const handleSubmit = async (values: PhoneLoginFormValues) => {
    return login(String(values.code));
  };

  const handleResend = async () => {
    onResendCode({ phone });
    setCountdown(120);
  };

  return (
    <Formik initialValues={{ phone }} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm }) => {
        return (
          <Form>
            <Paragraph size={5} textColor="text-400" align="center">
              <Trans id="auth.phoneLoginCode.description">{`Enter the code we've sent by SMS to (+${phone}).
            It can take up to 1 minute to receive the code`}</Trans>
            </Paragraph>
            <CodeInput onFull={submitForm} />
            <Row justify="center">
              <Button
                loading={isSubmitting}
                variation="secondary"
                iconName="clock"
                onClick={handleResend}
                disabled={!!currentCountdown}
              >
                {currentCountdown || <Trans id="auth.phoneLoginCode.button">Resend code</Trans>}
              </Button>
            </Row>
            {error && (
              <Row justify="center">
                <Alert
                  type="error"
                  size="small"
                  fill="var(--r-100)"
                  title={t({ id: 'auth.phoneLoginCode.errorTitle', message: 'Verification error' })}
                  canBeClosed={false}
                  description={error.userMessage}
                />
              </Row>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default PhoneLoginVerificationForm;
