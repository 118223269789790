import React, { useState, FC, useEffect } from 'react';
import styles from '@/features/booking/assets/scss/components/Filters.module.scss';
import { Text, DropdownWrapper } from '@gourban/ui-components';
import DateSelect from '@/features/booking/components/DateSelection/DateSelect';
import { useFormikContext } from 'formik';
import { BookingFiltersForm } from '@/features/booking/types';
import { t } from '@lingui/macro';
import { format } from 'date-fns';
import { DateFormats } from '@/core/enums';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';

interface DatePickerT {
  readOnly?: boolean;
  dropdownVisible?: boolean;
}

const DatePicker: FC<DatePickerT> = ({ readOnly, dropdownVisible }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(dropdownVisible ?? false);
  const { values, setFieldValue } = useFormikContext<BookingFiltersForm>();

  useEffect(() => {
    // Used for outside control of dropdown visibility
    if (dropdownVisible !== undefined) setIsDropdownVisible(dropdownVisible);
  }, [dropdownVisible]);

  return (
    <DropdownWrapper
      dropdownContainerClassName={styles.filters__dropdown}
      triggerElement={
        <Text
          name="date"
          fieldAttr={{
            id: 'filterDate',
            readOnly: true,
            onFocus: () => setIsDropdownVisible(true),
            placeholder: t({
              id: 'bookings.filters.pickUpDateReturnDate',
              message: 'Pickup date -> Return date',
            }),
          }}
          fieldProps={{
            clearable: !readOnly,
            onClear: () => {
              void setFieldValue('bookingDate', undefined);
            },
            label: '',
            borderless: true,
            iconLeft: { icon: 'calendar', color: 'icon-400' },
          }}
        />
      }
      onRequestClose={() => {
        setIsDropdownVisible(false);

        if (
          values.bookingDate?.from &&
          values.bookingTime?.from &&
          values.bookingTime?.to &&
          !values?.bookingTime.from.includes('--') &&
          !values?.bookingTime.to.includes('--')
        ) {
          void setFieldValue(
            'date',
            `${format(
              mergeDateTime(values.bookingDate.from, values.bookingTime.from),
              DateFormats.FULL_DATE_TIME_NO_YEAR,
            )} - ${format(
              mergeDateTime(
                values.bookingDate?.to ?? values.bookingDate.from,
                values.bookingTime.to,
              ),
              DateFormats.FULL_DATE_TIME_NO_YEAR,
            )}`,
          );
        }
      }}
      dropdownContent={<DateSelect />}
      isVisible={!readOnly && isDropdownVisible}
    />
  );
};

export default DatePicker;
