import { FC } from 'react';
import { useLingui } from '@lingui/react';

interface CurrencyDisplayT {
  value: number;
  currency: string;
}

const CurrencyDisplay: FC<CurrencyDisplayT> = ({ value, currency }) => {
  const { i18n } = useLingui();

  return (
    <>
      {i18n.number(value, {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}
    </>
  );
};

export default CurrencyDisplay;
